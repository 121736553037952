<template>
    <v-app-bar app dense flat id="navbar" style="background-color: #f8f8f8;">
        <p class="mt-4">Let's <span class="font-weight-bold purple--text">QUIZ</span></p>
        <v-btn outlined class="ml-auto">Log Out</v-btn>
    </v-app-bar>
</template>
  
<script>

export default {
    name: 'Navigation',

    data: () => ({

    }),
    methods: {

    }
};
</script>
  
<style>
/* #navbar {
    background: transparent;
} */
</style>
  