<template>
    <v-card flat outlined class="mb-2">
        <v-card-title>Fundamentals of Programming (Java Programming)</v-card-title>
        <v-card-text class="d-flex">
            <p class="mr-6">40 Items</p>
            <p>April 12, 2023</p>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'QuizCard',
    data: () => ({
        test: 'testing'
    })
}
</script>
<style lang="">
    
</style>